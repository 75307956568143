import Vue from "vue";
import DashboardPlugin from "./plugins/dashboard-plugin";
import App from "./App.vue";
import i18n from "./i18n";

// router setup
import router from "./routes/router";

const axios = require("axios");

axios.defaults.baseURL = '';
if (window.location.hostname == 'localhost') {
  axios.defaults.baseURL = 'http://localhost';
  // axios.defaults.baseURL = 'http://dev1.com';
  // axios.defaults.baseURL = 'https://test3.posking.ca';
}

// plugin setup
Vue.use(DashboardPlugin);

/* eslint-disable no-new */
new Vue({
  el: "#app",
  render: h => h(App),
  router,
  i18n
});

